import { SVG } from 'types/WebMicrofrontends';

export const PlayButtonIcon = ({ classes = '', fillColor = '' }: SVG) => (
  <svg
    className={classes}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      d="M75 135C41.925 135 15 108.075 15 75C15 41.925 41.925 15 75 15C108.075 15 135 41.925 135 75C135 108.075 108.075 135 75 135ZM75 0C65.1509 0 55.3982 1.93993 46.2987 5.70904C37.1993 9.47814 28.9314 15.0026 21.967 21.967C7.90176 36.0322 0 55.1088 0 75C0 94.8912 7.90176 113.968 21.967 128.033C28.9314 134.997 37.1993 140.522 46.2987 144.291C55.3982 148.06 65.1509 150 75 150C94.8912 150 113.968 142.098 128.033 128.033C142.098 113.968 150 94.8912 150 75C150 65.1509 148.06 55.3982 144.291 46.2987C140.522 37.1993 134.997 28.9314 128.033 21.967C121.069 15.0026 112.801 9.47814 103.701 5.70904C94.6018 1.93993 84.8491 0 75 0ZM60 108.75L105 75L60 41.25V108.75Z"
      fill={fillColor}
    />
  </svg>
);
