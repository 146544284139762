import { SVG } from 'types/WebMicrofrontends';

export const InfoIcon = ({
  classes = '',
  fillColor = '',
  strokeColor = '',
  strokeWidth = ''
}: SVG) => (
  <svg
    className={classes}
    viewBox="0 0 18.01 17.98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      d="M9 18.3145C13.9706 18.3145 18 14.285 18 9.31445C18 4.34389 13.9706 0.314453 9 0.314453C4.02944 0.314453 0 4.34389 0 9.31445C0 14.285 4.02944 18.3145 9 18.3145Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      d="M10.7041 11.7545C10.3041 12.4585 9.74411 13.2105 9.42411 13.2105C9.13611 13.2105 9.15211 12.9065 9.24811 12.5385L10.7841 6.85848L10.6881 6.76248L7.15211 7.21048L7.04011 7.72248L7.90411 7.81848C8.19211 7.83448 8.25611 8.04248 8.16011 8.44248L7.00811 12.6505C6.68811 13.8505 7.04011 14.5065 8.08011 14.5065C9.60011 14.5065 10.5121 13.3705 11.1521 12.0745L10.7041 11.7545ZM9.98411 3.59448C9.08811 3.59448 8.65611 4.26648 8.65611 4.89048C8.65611 5.53048 9.08811 5.96248 9.80811 5.96248C10.5441 5.96248 11.1201 5.49848 11.1201 4.66648C11.1201 3.96248 10.6241 3.59448 9.98411 3.59448Z"
      fill="white"
    />
  </svg>
);
