import { SVG } from 'types/WebMicrofrontends';

export const LockIcon = ({
  classes = '',
  fillColor = '',
  strokeColor = '',
  strokeWidth = ''
}: SVG) => (
  <svg
    className={classes}
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91374 6.95999H3.01374V6.85999V4.89999C3.01374 3.62667 3.51506 2.40567 4.40713 1.50557C5.29917 0.605492 6.50884 0.1 7.76998 0.1C8.39446 0.1 9.01284 0.224106 9.58983 0.465255C10.1668 0.706405 10.6911 1.05989 11.1328 1.50557C11.5745 1.95125 11.925 2.48039 12.1641 3.06282C12.4032 3.64525 12.5262 4.26952 12.5262 4.89999V6.85999V6.95999H12.6262H13.5975C14.0858 6.95999 14.5544 7.15573 14.9 7.50445C15.2456 7.8532 15.44 8.32639 15.44 8.81998V18.62C15.44 19.1136 15.2456 19.5868 14.9 19.9355C14.5544 20.2842 14.0858 20.48 13.5975 20.48H1.9425C1.45413 20.48 0.985576 20.2842 0.639971 19.9355C0.294335 19.5868 0.1 19.1136 0.1 18.62V8.81998C0.1 7.78689 0.929871 6.95999 1.9425 6.95999H2.91374ZM4.75624 6.85999V6.95999H4.85624H10.6837H10.7837V6.85999V4.89999C10.7837 4.09402 10.4664 3.32089 9.90134 2.75071C9.33622 2.1805 8.56957 1.86 7.76998 1.86C6.9704 1.86 6.20375 2.1805 5.63863 2.75071C5.07354 3.32089 4.75624 4.09402 4.75624 4.89999V6.85999ZM7.76998 15.78C8.31198 15.78 8.83159 15.5627 9.21456 15.1763C9.5975 14.7899 9.81248 14.266 9.81248 13.72C9.81248 12.5775 8.89411 11.66 7.76998 11.66C7.22799 11.66 6.70838 11.8772 6.32541 12.2637C5.94246 12.65 5.72749 13.1739 5.72749 13.72C5.72749 14.266 5.94246 14.7899 6.32541 15.1763C6.70838 15.5627 7.22799 15.78 7.76998 15.78Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
);
