import { CheckMarkIcon } from './icons/CheckMarkIcon';
import { ChevronFatIcon } from './icons/ChevronFatIcon';
import { ChevronIcon } from './icons/ChevronIcon';
import { CloseIcon } from './icons/CloseIcon';
import { InfoIcon } from './icons/InfoIcon';
import { LockIcon } from './icons/LockIcon';
import { LockShadowIcon } from './icons/LockShadowIcon';
import { OutsideOIcon } from './icons/OutsideOIcon';
import { PlayButtonIcon } from './icons/PlayButtonIcon';
import { PlayIcon } from './icons/PlayIcon';
import { ProfileIcon } from './icons/ProfileIcon';
import { RefreshIcon } from './icons/RefreshIcon';
import { StarIcon } from './icons/StarIcon';

export type IconProps = {
  /** Fill color of the icon */
  color?: string;
  /** Size of the icon */
  size?:
    | 'xsmall'
    | 'small'
    | 'small-plus'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';
  /** Type of icon */
  type:
    | 'checkMark'
    | 'chevron'
    | 'chevronFat'
    | 'close'
    | 'info'
    | 'lock'
    | 'lockShadow'
    | 'outsideO'
    | 'play'
    | 'playButton'
    | 'profile'
    | 'refresh'
    | 'star';
  /** Additional classes to add to the icon component */
  classes?: string;
  /** Stroke color */
  strokeColor?: string;
  /** Stroke width */
  strokeWidth?: string;
};

const iconMap = {
  checkMark: CheckMarkIcon,
  chevron: ChevronIcon,
  chevronFat: ChevronFatIcon,
  close: CloseIcon,
  info: InfoIcon,
  lock: LockIcon,
  lockShadow: LockShadowIcon,
  outsideO: OutsideOIcon,
  play: PlayIcon,
  playButton: PlayButtonIcon,
  profile: ProfileIcon,
  refresh: RefreshIcon,
  star: StarIcon
};

export const Icon = ({
  color = 'currentColor', // TODO - move this to a constants file or theme
  size,
  type,
  classes = '',
  strokeColor = '',
  strokeWidth = ''
}: IconProps) => {
  const IconComponent = iconMap[type];

  return (
    <IconComponent
      classes={`${size ? `icon--${size}` : ''} ${classes}`.trim()}
      fillColor={color}
      strokeColor={strokeColor}
      strokeWidth={strokeWidth}
    />
  );
};
