import { SVG } from 'types/WebMicrofrontends';

export const LockShadowIcon = ({ classes = '' }: SVG) => (
  <svg
    className={classes}
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5278 16.0998C9.04298 16.0998 9.53706 15.8933 9.90135 15.5258C10.2656 15.1582 10.4703 14.6597 10.4703 14.1398C10.4703 13.052 9.59617 12.1798 8.5278 12.1798C8.01262 12.1798 7.51853 12.3863 7.15425 12.7539C6.78996 13.1215 6.5853 13.62 6.5853 14.1398C6.5853 14.6597 6.78996 15.1582 7.15425 15.5258C7.51853 15.8933 8.01262 16.0998 8.5278 16.0998ZM14.3553 7.27985C14.8705 7.27985 15.3645 7.48635 15.7288 7.85392C16.0931 8.22149 16.2978 8.72002 16.2978 9.23984V19.0398C16.2978 19.5596 16.0931 20.0582 15.7288 20.4258C15.3645 20.7933 14.8705 20.9998 14.3553 20.9998H2.70031C2.18513 20.9998 1.69105 20.7933 1.32676 20.4258C0.962468 20.0582 0.757813 19.5596 0.757813 19.0398V9.23984C0.757813 8.15205 1.63194 7.27985 2.70031 7.27985H3.67156V5.31985C3.67156 4.02029 4.18319 2.77396 5.09392 1.85503C6.00464 0.936108 7.23984 0.419861 8.5278 0.419861C9.16553 0.419861 9.79701 0.546603 10.3862 0.79285C10.9754 1.0391 11.5107 1.40003 11.9617 1.85503C12.4126 2.31004 12.7703 2.85021 13.0144 3.44471C13.2584 4.0392 13.384 4.67638 13.384 5.31985V7.27985H14.3553ZM8.5278 2.37986C7.75502 2.37986 7.0139 2.68961 6.46747 3.24096C5.92104 3.79232 5.61405 4.54012 5.61405 5.31985V7.27985H11.4415V5.31985C11.4415 4.54012 11.1346 3.79232 10.5881 3.24096C10.0417 2.68961 9.30057 2.37986 8.5278 2.37986Z"
      fill="#EA2127"
    />
    <path
      d="M2.91374 6.95999H3.01374V6.85999V4.89999C3.01374 3.62667 3.51506 2.40567 4.40713 1.50557C5.29917 0.605492 6.50884 0.1 7.76998 0.1C8.39446 0.1 9.01284 0.224106 9.58983 0.465255C10.1668 0.706405 10.6911 1.05989 11.1328 1.50557C11.5745 1.95125 11.925 2.48039 12.1641 3.06282C12.4032 3.64525 12.5262 4.26952 12.5262 4.89999V6.85999V6.95999H12.6262H13.5975C14.0858 6.95999 14.5544 7.15573 14.9 7.50445C15.2456 7.8532 15.44 8.32639 15.44 8.81998V18.62C15.44 19.1136 15.2456 19.5868 14.9 19.9355C14.5544 20.2842 14.0858 20.48 13.5975 20.48H1.9425C1.45413 20.48 0.985576 20.2842 0.639971 19.9355C0.294335 19.5868 0.1 19.1136 0.1 18.62V8.81998C0.1 7.78689 0.929871 6.95999 1.9425 6.95999H2.91374ZM4.75624 6.85999V6.95999H4.85624H10.6837H10.7837V6.85999V4.89999C10.7837 4.09402 10.4664 3.32089 9.90134 2.75071C9.33622 2.1805 8.56957 1.86 7.76998 1.86C6.9704 1.86 6.20375 2.1805 5.63863 2.75071C5.07354 3.32089 4.75624 4.09402 4.75624 4.89999V6.85999ZM7.76998 15.78C8.31198 15.78 8.83159 15.5627 9.21456 15.1763C9.5975 14.7899 9.81248 14.266 9.81248 13.72C9.81248 12.5775 8.89411 11.66 7.76998 11.66C7.22799 11.66 6.70838 11.8772 6.32541 12.2637C5.94246 12.65 5.72749 13.1739 5.72749 13.72C5.72749 14.266 5.94246 14.7899 6.32541 15.1763C6.70838 15.5627 7.22799 15.78 7.76998 15.78Z"
      fill="#FDD20A"
      stroke="black"
      strokeWidth="0.2"
    />
  </svg>
);
